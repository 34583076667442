import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        let _this = this;
        $(document).ready(function() {
            _this.updateValuesSelect($(_this.element).find('.constraint-select')[0]);
        });
    }

    updateFields(event) {
        this.updateValuesSelect(event.target)
    }

    checkOptionLimit(event) {
        let selectElement = event.target;
        if (selectElement.selectedOptions.length > $(selectElement).prop('maxSelections')) {
            $(selectElement).attr('data-toggle', 'tooltip')
                .attr('title', "Vous ne pouvez pas sélectionner plus de " + $(selectElement).prop('maxSelections') + " option(s).")
                .tooltip('enable')
                .tooltip('show')

            $(selectElement).siblings('.select2').on('focusout', function() {
                $(selectElement).tooltip('hide')
                    .tooltip('disable');
            });
            selectElement.selectedOptions[selectElement.selectedOptions.length - 1].selected = false;
        }
        this.handleBlankOption(selectElement); // remove selection of the blank option
        $(selectElement).trigger('change');
    }

    updateValuesSelect(constraintSelect) {
        let maxOptions = Infinity;
        let constraint = constraintSelect.value;
        let valuesSelect = $(constraintSelect).parents('.nested-fields').find('.values-select');

        switch (constraint) {
            case 'always':
                valuesSelect.attr({ disabled: true, multiple: true }).removeAttr('required');
                maxOptions = 0;
                break;
            case '>=':
            case '<=':
                valuesSelect.attr({ required: true }).removeAttr('disabled multiple');
                maxOptions = 1;
                break;
            case 'in':
            case 'not in':
                valuesSelect.attr({ required: true, multiple: true }).removeAttr('disabled');
                break;
            case 'between':
            case 'not between':
                valuesSelect.attr({ required: true, multiple: true }).removeAttr('disabled');
                maxOptions = 2;
                break;
            default:
                valuesSelect.attr({ required: true }).removeAttr('disabled');
        }
        this.limitSelectedOptions(valuesSelect[0], maxOptions);
    }

    limitSelectedOptions(selectElement, max = Infinity) {
        $(selectElement).prop('maxSelections', max);
        this.removeOverflowOptions(selectElement, max);
    }

    // Work with simple select only because it is not possible to select more than one option in a simple select
    // because even if there are multiple "selected", selectedOptions.length will always be 1
    removeOverflowOptions(selectElement, max) {
        let selectedOptions = selectElement.selectedOptions
        while (selectedOptions.length > max) {
            selectedOptions[selectedOptions.length - 1].selected = false;
        }
        $(selectElement).trigger('change');
    }

    handleBlankOption(selectElement) {
        selectElement.item(0).selected = false
    }
}

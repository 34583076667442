import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static values = {
        input: { type: String, default: 'search' },
        filters: { type: String, default: 'filter' }
    }

    connect() {
        const input = $(`#${this.inputValue}`)[0];
        const filters = $(`.${this.filtersValue}`)
        const form = $(input).closest('form')[0]
        const baseURI = window.location
        this.fillInputField()

        form.addEventListener('submit', () => {
            (input.value !== '') ? this.saveSearch(input, filters) : window.sessionStorage.removeItem('search')
        })
    }

    saveSearch(input, filters) {
        window.sessionStorage.setItem('search', JSON.stringify(
            {
                id: input.id,
                value: input.value,
                filters: $.map(filters, function(filter) {
                    return { id: filter.id, value: filter.value }
                }),
                url: window.location.pathname === '/' ? '/cuvees' : window.location.pathname,
                ean_search: $(input).closest('form').find('.fa-barcode').length > 0
            }))
    }

    fillInputField() {
        const inputData = JSON.parse(window.sessionStorage.getItem('search'))
        if (inputData !== null && !inputData.ean_search && inputData.url === window.location.pathname) {
            const input = document.getElementById(inputData.id)
            input.value = inputData.value
            $.each(inputData.filters, function ( _idx, filter ) {
                $(`#${filter.id}`)[0].value = filter.value
            } )
            $(input).closest('form')[0].requestSubmit();
        }
    }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  show_spinner(event) {
    $(".spinner").show();
    $(".content").hide();
  }

  hide_collapse(event) {
    $('.collapse').collapse('hide');
  }

}

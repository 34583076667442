import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        // do not fill input fields if this is a preview page
        if (!document.documentElement.hasAttribute("data-turbo-preview")) {
            const search = $('#search')[0]
            const filters = $('#filters')
            const sorters = $('#sorters')
            const hiddenFields = $('#hidden_fields')
            const params = { search: search, filters: filters, sorters: sorters, hiddenFields: hiddenFields }
            const form = $(search).closest('form')[0]

            this.fillInputFields()

            form.addEventListener('submit', () => {
                search.value !== '' ? this.saveSearch(params) : window.sessionStorage.removeItem('search')
            })
        }
    }

    saveSearch(params) {
        window.sessionStorage.setItem('search', JSON.stringify(
            {
                url: this.formatRoutes(window.location.pathname),
                search: { id: params.search.id, value: params.search.value },
                filters: this.dataFromFields(params.filters, 'select'),
                sorters: this.dataFromFields(params.sorters, 'select'),
                hiddenFields: this.dataFromFields(params.hiddenFields, 'input')
            }))
    }

    fillInputFields() {
        let searchData = JSON.parse(window.sessionStorage.getItem('search'))
        if (searchData !== null && searchData.url === this.formatRoutes(window.location.pathname)) {
            const input = document.getElementById(searchData.search.id)
            input.value = searchData.search.value
            this.fillFields(searchData.filters)
            this.fillFields(searchData.sorters)
            this.fillFields(searchData.hiddenFields)
            this.switchForm(searchData.hiddenFields.find(field => field.id === 'ean_search_enabled'))
            $(input).closest('form')[0].requestSubmit();
        }
    }

    dataFromFields(fields, fieldType) {
        return $.map(fields.find(fieldType), function(field) {
            return { id: field.id, value: field.value }
        })
    }

    fillFields(fieldsData) {
        $.each(fieldsData, function ( _idx, fieldData ) {
            $(`#${fieldData.id}`)[0].value = fieldData.value
        } )
    }

    switchForm(eanSearchEnabled) {
        if (eanSearchEnabled.value === 'true') {
            $('#switch-form-btn').find('i').toggleClass('fa-barcode fa-text')
            document.getElementById('search').placeholder = 'Rechercher un code barre'
        }
    }

    formatRoutes(route) {
        let formattedRoute
        switch (route) {
            case '/vendeurs':
                formattedRoute = '/vendeurs/products'
                break;
            default:
                formattedRoute = route
        }
        return formattedRoute
    }
}

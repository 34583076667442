import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static values = {
        resetFormId: String
    }

    display_filename(event) {
        // Add the following code if you want the name of the file appear on select
        let fileName = event.target
            .value
            .split("\\")
            .pop();
        $(".custom-file-label").addClass("selected overflow-hidden")
            .html(fileName);
    }

    resetForm(event) {
        let form = document.getElementById(this.resetFormIdValue)
        if (form !== null) {
            form.reset()
        }
    }

    switchForm(event) {
        let switchFormButton = $(event.target).closest('#switch-form-btn')
        let placeholders = ['Rechercher un mot clé', 'Rechercher un code barre']
        let form = switchFormButton.closest('form')[0]
        let input = $(form).find('#search')[0]
        let ean_search_enabled = $(form).find('#ean_search_enabled')[0]
        switchFormButton.find('i').toggleClass('fa-barcode fa-text')
        input.placeholder = input.placeholder === placeholders[0] ? placeholders[1] : placeholders[0]
        ean_search_enabled.value = ean_search_enabled.value === 'true' ? 'false' : 'true'
        $(input).val('')
        form.requestSubmit()
    }
}

import { Controller } from '@hotwired/stimulus'
import {get} from "@rails/request.js";

export default class extends Controller {
    static targets = ['selectInput', 'slider', 'amount', 'priceRange', 'autocomplete']
    static values = {
        type: String,
        field: String
    }

    connect() {
        if (this.hasAutocompleteTarget) {
            this.updateSelect()
        }
        if (this.hasSliderTarget) {
            var amountTarget = $(this.amountTarget)
            var target = $(this.sliderTarget)
            var priceRangeTarget = $(this.priceRangeTarget)
            $(target).slider({
                range: true,
                min: Number(target.data('min')),
                max: Number(target.data('max')),
                step: 0.01,
                values: [target.data('min'), target.data('max')],
                slide: function(event, _ui) {
                    $(amountTarget).html('Prix (€): ' + _ui.values[0] + ' - ' + _ui.values[1] );
                },
                stop: function(event, _ui) {
                    $.post(`${window.location.pathname}/count_wines`,
                            { min: _ui.values[0], max: _ui.values[1], authenticity_token: $('[name="csrf-token"]')[0].content },
                            function(data){
                                if(data){
                                    $(event.target).closest('.card-body').find('.total-wines').html(data.total)
                                }
                            }
                    )
                    $(priceRangeTarget)[0].value = _ui.values
                },
             })
        }
    }

    select_answer(event) {
        this.selectInputTarget.click()
        let card = this.selectInputTarget.nextElementSibling

        $(card).toggleClass('choosen')
    }

    updateSelect() {
        let path = `${window.location.pathname}/autocomplete_values`
        let params = new URLSearchParams();
        params.append('target', this.autocompleteTarget.id);
        params.append('type', this.typeValue);
        params.append('field', this.fieldValue);
        get(`${path}?${params}`, {
            responseKind: 'turbo-stream'
        });
    }

    updateWinesCount(event) {
        let path = `${window.location.pathname}/count_wines_autocomplete`
        let params = new URLSearchParams();
        params.append('target', this.autocompleteTarget.id);
        params.append('type', this.typeValue);
        params.append('value', event.target.value);
        $.get(`${path}?${params}`, function (data) {
            $(event.target).closest('.row').find('.total-wines').html(data.total)
        });
    }

    displaySessionInfo(event) {
        let sessionInfoElement = $('#question').find('.session-info')
        sessionInfoElement.toggleClass('d-none')
    }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = []
    static values = {
        open: { type: String, default: 'fa-chevron-up' }, // item to display when collapse is opened
        close: { type: String, default: 'fa-chevron-down' }, // item to display when collapse is closed
        collapseClass: String, // class of the collapsed element
        dynamicElementContainer: { type: String, default: '.card' }, // element where the dynamic element is
        dynamicElementClass: { type: String, default: '.dynamic-element' }, // class which tells where is the dynamic element
        messages: { type: Array, default: ['Voir moins', 'Voir plus'] } // messages to display if there is a second element
    }

    connect() {
        this.change_collapse_panel(
            'show.bs.collapse',
            this.openValue,
            this.closeValue,
            this.dynamicElementContainerValue,
            this.dynamicElementClassValue,
            this.messagesValue[0])
        this.change_collapse_panel(
            'hide.bs.collapse',
            this.closeValue,
            this.openValue,
            this.dynamicElementContainerValue,
            this.dynamicElementClassValue,
            this.messagesValue[1])
    }

    change_collapse_panel(event, to_add, to_remove, dynamicElementContainer, dynamicElementClass, message) {
        let collapseElements = $(this.collapseClassValue);
        collapseElements.on(event, function (event) {
            event.stopPropagation();
            let dynamic_element = $(this).closest(dynamicElementContainer).find(dynamicElementClass)[0];
            dynamic_element.children[0].classList.add(to_add);
            dynamic_element.children[0].classList.remove(to_remove);
            if (dynamic_element.children[1]) {
                dynamic_element.children[1].innerHTML = message
            }
        });
    }
}

import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = ['input', 'output']
    static values = {
        url: String,
    }

    change(event) {
        let params = new URLSearchParams();
        params.append('input', this.inputTargets.map(t => t.selectedOptions.length > 0 ? t.selectedOptions[0].value : ''));
        params.append('target', this.outputTargets.map(t => t.id));
        get(`${this.urlValue}?${params}`, {
            responseKind: 'turbo-stream'
        })
    }
}

import { Controller } from '@hotwired/stimulus'
import { customMatch, customMatchWithoutParentheses } from './select2/matchers'

export default class extends Controller {
    static targets = ['selectDom']
    static values = {
        select2: { type: String, default: '.select2' },
        allowClear: { type: Boolean, default: true },
        placeholder: { type: String, default: '' },
        dispatchEvent : { type: Boolean, default: false },
        theme: { type: String, default: 'default' },
        matcher: { type: String, default: 'default'},
        itemsUrl : { type: String, default: '' },
        selected: {type: Array, default: []}
    }

    static classes = ['default']

    connect() {
        let _this = this;
        let classes = this.hasDefaultClass ? this.defaultClasses : ''
        let matcher = this.matcherValue === 'no_parentheses' ? customMatchWithoutParentheses : customMatch

        let select2Elements = $(this.element).find(this.select2Value)

        if (this.itemsUrlValue) {
            this.renderLargeSelect2(select2Elements, matcher, classes)
        } else {
            this.renderSelect2(select2Elements, matcher, classes)
        }
        select2Elements.addClass(classes)

        if (this.dispatchEventValue) {
            select2Elements.on('select2:select select2:unselect', function() {
                _this.createEvent(this)
            })
        }
    }

    createEvent(object) {
        let event = new Event('change', {bubbles: true})
        object.dispatchEvent(event)
    }

    renderSelect2(elements, matcher, classes) {
        elements
            .select2({
                allowClear: this.allowClearValue,
                placeholder: this.placeholderValue,
                matcher: matcher,
                theme: this.themeValue,
                dropdownCssClass: classes
            })
    }

    async renderLargeSelect2(elements, matcher, classes) {
        let items = await this.getData()

        if (this.selectedValue) {
            let _this = this
            this.selectedValue.forEach(function(selectedValue) {
                let selected_item = items.find(element =>
                    element.id === selectedValue
                );
                $(_this.selectDomTarget).append(new Option(selected_item.text, selected_item.id, true, true))
            })
        }

        elements.select2({
            ajax: {},
            items: items,
            pageSize: 10,
            matcher: matcher,
            dataAdapter: $.fn.select2.amd.require('CustomDataAdapter'),
            placeholder: this.placeholderValue,
            allowClear: this.allowClearValue,
            theme: this.themeValue,
            dropdownCssClass: classes
        });
    }

    async getData() {
        let results = await $.getJSON(this.itemsUrlValue)
        return results.items
    }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static values = {
        popContent: { type: String, default: 'Copié !' }
    }

    connect() {
        $('.pop').popover({
            container: 'body',
            content: this.popContentValue,
            placement: 'top',
        })
    }

    copy_text_to_clipboard(event) {
        navigator.clipboard.writeText(event.params['text']);
        setTimeout(function () {
            $(event.target).closest('.pop').popover('hide');
        }, 2000)
    }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ['gammeId', 'startDate', 'exists']

    async checkUniqueness(event) {
        event.preventDefault()

        let _this = this
        let submission = false
        let params = new URLSearchParams();
        params.append('gamme_id', this.gammeIdTarget.value);
        params.append('start_date', this.startDateTarget.value);
        await $.get(`/inventories/check_uniqueness?${params}`, function (data) {
            _this.existsTarget.value = data.exists
            if (data.exists) {
                submission = confirm('Un cadencier avec les mêmes dates existe déjà, voulez-vous le remplacer ?')
            } else {
                submission = true;
            }
        })
        if (submission) {
            $(event.target).closest('form')[0].requestSubmit()
        }
    }
}

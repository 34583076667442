import {Controller} from "@hotwired/stimulus"
import {get, post} from '@rails/request.js'

export default class extends Controller {
    static targets = ["pagination"]
    static values  = {
        lastHeader: String
    }

    scroll_half_page(event){
        var current = event.currentTarget;
        
        let url = '#'
        if (this.hasPaginationTarget)
        {
            url = $(this.paginationTarget).find("a[rel='next']").attr("href")

            if (url)
            {   
                if (current.scrollTop >= current.scrollHeight - current.offsetHeight) {
                    
                    $(this.paginationTarget).remove()
                    this.loadMore(url);
                }
            }
        }
    }

    scroll() {
        var body = document.body, html = document.documentElement

        let url = '#'
        if (this.hasPaginationTarget && this.paginationTarget.parentElement.offsetParent !== null)
        {
            url = $(this.paginationTarget).find("a[rel='next']").attr("href")

            if (url)
            {
                
                let height = Math.max(body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight)

                // look if user is at bottom of the page
                if (window.scrollY >= height - window.innerHeight - 500) {
                    $(this.paginationTarget).remove()
                    this.loadMore(url);
                }
            }
        }
    }

    // TODO: Change this to use the new search technique
    loadMore(url){
        if (url.includes('search')) {
            post(url, {
                responseKind: 'turbo-stream',
                body: Object.assign(
                    { search: $('#search').val(),
                      search_wia: $('#search_wia').val(),
                      ean_search: $('#ean_search').val(),
                      filter: $('#filter').val() },
                    this.retrieveParams('hidden-field'),
                    this.retrieveParams('sort'),
                    this.retrieveParams('filter'))
            })
        } else {
            get(url, {
                responseKind: 'turbo-stream'
            })
        }
    }

    retrieveParams(className) {
        let params = document.getElementsByClassName(className)
        let extraParams = {}
        if (params.length === 0) return extraParams

        if (params.length === 1) {
            extraParams[params[0].name] = params[0].value
        }
        else if (className === 'hidden-field') {
            Array.from(params).forEach((param) => {
                extraParams[param.name] = param.value
            })
        } else if (className === 'filter') {
            extraParams['filter'] = {}
            Array.from(params).forEach((filterField) => {
                extraParams['filter'][filterField.name.match(/\[(.*)\]/)[1]] = filterField.value
            })
        } else if (className === 'sort') {
            extraParams['sort'] = { column: $('#sort_column').val(), order: $('#sort_order').val() }
        }
        return extraParams
    }
}

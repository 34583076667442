import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        waitTime: Number,
        inputId: { type: String, default: 'search' }
    }

    connect() {
        var timer = 0;
        const waitTime = this.waitTimeValue;
        const input = $(`#${this.inputIdValue}`);
        const form  = $(input).closest('form').get(0);

        $(input).keyup(function() {
            clearTimeout(timer)
            timer = setTimeout(() => {
                form.requestSubmit()
            }, waitTime)
        })
    }
}

import { Controller } from '@hotwired/stimulus'
import JsBarcode from "jsbarcode";

export default class extends Controller {
    static targets = ['cepageId', 'nestedFields']

    nestedFieldsTargetConnected() {
        let self = this;

        this.setLastTabPosition()

        $(document).ready(function() {
            if (document.querySelectorAll('.svg-barcode').length > 0) {
                let barcodes = $('.svg-barcode')
                Array.from(barcodes).forEach(barcode => {
                    self.setBarcode(barcode, barcode.getAttribute('ean'))
                });
            }
        })

        $('#barcodes, #clean_cepages').on('cocoon:before-remove', function(event, task) {
            let confirmation = confirm("Êtes vous sûr ?");
            if( confirmation === false ){
                event.preventDefault();
            }
        });

        $('#barcodes').on('cocoon:after-insert', function (e, insertedItem, _originalEvent) {
            insertedItem.find('.svg-barcode')[0].id = new Date().getTime()
        });
    }

    changeCepage(event) {
        let self = this
        let path = '/clean_cepages/fetch_cepage_id'
        let params = new URLSearchParams();
        params.append('display_name', event.target.value);
        $.get(`${path}?${params}`, function(data) {
            self.cepageIdTarget.value = data.cepage_id
        });
    }

    updateBarcode(event) {
        let ean = event.target.value;
        let svgBarcode = $(event.target).closest('.nested-fields').find('.svg-barcode')[0]

        if (ean.length !== 13) return;

        this.setBarcode(svgBarcode, ean)
    }

    setBarcode(element, ean) {
        try {
            JsBarcode(element, ean, {format: "ean13", width: 1.5, height: 60});
        } catch (exc) {
            console.log("Not valid EAN");
        }
    }

    setLastTabPosition() {
        if (window.sessionStorage.getItem('activeTab')) {
            $(`#${window.sessionStorage.getItem('activeTab')}`).tab('show')
            window.sessionStorage.removeItem('activeTab')
        }
        $('.nav-tab').on('click', function (e) {
            window.sessionStorage.setItem('activeTab', $(e.target).closest('a').attr('id'));
        })
    }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['selectBoxes']

    set_same_question(event) {
        var selectBoxes = this.selectBoxesTargets;
        var selectChoice = selectBoxes[0].value;


        selectBoxes.slice(1).forEach(element => {
            $(`#${element.id}`).val(selectChoice).trigger('change');
            console.log(typeof selectChoice)
            //$(`#${element.id}`).select2().select2();
        });
    }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/activestorage").start()
require("channels")
require('controllers')

import '@rails/request.js'
import '@hotwired/turbo-rails'


import $ from 'jquery';

window.$ = $;
window.jQuery = $;

// select2
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';
require('controllers/select2/adapters.js')

require("bootstrap/dist/js/bootstrap.min")

// nested model, required by cocoon gem
import "cocoon-js"

// jquery flowchart requirements:
import 'jquery.flowchart/jquery.flowchart.min.css';
import 'jquery-ui/themes/base/all.css';
require('jquery-ui/ui/widget')
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/slider')
require('jquery.flowchart/jquery.flowchart.min')

//ahoy analytics
import ahoy from "ahoy.js";
window.ahoy = ahoy;

import toastr from 'toastr'
toastr.options = { timeOut: 5000 }
window.toastr = toastr

import jsbarcode from 'jsbarcode';
window.JsBarcode = jsbarcode;

window.tinyMCE = require('tinymce/tinymce.min')
require('tinymce/themes/silver/theme.min')
require('tinymce/icons/default/icons.min')
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/skin.mobile.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/ui/oxide/content.mobile.min.css';
require('jquery-ui/ui/widgets/sortable')


$.fn.refreshDataSelect2 = function (data) {
  this.select2('data', data);

  // Update options
  var $select = $(this[0]);
  var options = data.map(function(item) {
      if ($select.val() == item.id){
        return '<option value="' + item.id + '" selected>' + item.text + '</option>';
      } else {
        return '<option value="' + item.id + '">' + item.text + '</option>';
      }

  });
  $select.html(options.join('')).change(function(e){
    e.preventDefault()
  });
};


$.fn.railsSortable = function(options) {
  options = options || {};
  var settings = $.extend({}, options);

  settings.update = function(event, ui) {
    if (typeof options.update === 'function') {
      options.update(event, ui);
    }

    if($(this).data('update-url')){
      $.ajax({
        type: 'POST',
        url: $(this).data('update-url'),
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          rails_sortable: $(this).sortable('toArray'),
        }),
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        }
      });
    } else {
      toastr.error('undefined update sort url')
    }

  }

  this.sortable(settings);
};

$(window).on('load turbo:render', function(){
  function previewImage(imgFile, elPreview) {
    var reader   = new FileReader();

    reader.onload = function (e) {
      $(elPreview).parent('div').find('img').attr({src: e.target.result, alt: imgFile.name, width: 50, height: 50});
    };

    reader.readAsDataURL(imgFile);

    $(elPreview).parent('div').find('.image-name').html('');
    $(elPreview).parent('div').find('.image-name').append("<a href='#' class='ml-2 img-remover'>delete</a>");
  }

  function previewImageBig(imgFile, elPreview) {
    var reader   = new FileReader();

    reader.onload = function (e) {
      $(elPreview).parent('div').find('img').attr({src: e.target.result, width: 300, height: 150});
    };

    reader.readAsDataURL(imgFile);
    // $(elPreview).parent('div').find('.image-name-big').html(imgFile.name);
  }

  $('body').on('change', '.image-input', function(){
    var imgFile  = this.files[0];
    previewImage(imgFile, this);
  });

  $('body').on('click', '.image-uploader', function(){
    $(this).parent('div').find('input').trigger('click');
  });

  $('body').on('change', '.image-input-big', function(){
    var imgFile  = this.files[0];
    previewImageBig(imgFile, this);
  });

  $('body').on('click', '.image-uploader-big', function(){
    $(this).parent('div').find('input').trigger('click');
  });

  // when user click on camera button
  $('body').on('click', '.scan__camera', function(){
    $(this).parent('div').find('input').trigger('click');
  });


  $('body').on('click', '.img-remover', function(el){
    // TODO: Do through check
    var linkUrl = $(this).prop('href');

    if(!linkUrl.match(/\#$/)) {
      $.get(linkUrl, function(data) {
        console.log(data);
      })
    }

    $(this).parents('.row').first().find('img').prop("src", "");
    $(this).parents('.row').first().find('img').prop("alt", "");
    $(this).parent('label').prev('input').val("");
    $(this).parent('label').html("");

    el.preventDefault();
  });

  $('.rsortable').railsSortable();
});

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

String.prototype.titleize = function() {
  var string_array = this.split(' ');
  string_array = string_array.map(function(str) {
     return str.capitalize();
  });
  return string_array.join(' ');
}

window.randString = function() {
  return Math.random().toString(36).substring(7)
}

window.select2Data = function(data){
  var newData = []
  var totalData = data.length
  if(totalData < 1) return newData

  for(let i=0; i < totalData; i++){
    if(data[i]){
      newData.push({id: data[i][1], text: data[i][0]})
    }
  }
  return newData
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['parentId', 'parentType']

    changeParent(event) {
        let parent = event.target.value
        this.parentIdTarget.value = parent.split(',')[0]
        this.parentTypeTarget.value = parent.split(',')[1]
    }
}

import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {
    static targets = ["pagination"]
    static values  = {
        lastHeader: String
    }

    scroll() {
        let body = document.body, html = document.documentElement

        let url = '#'
        if (this.hasPaginationTarget && this.paginationTarget.parentElement.offsetParent !== null)
        {
            url  = this.paginationTarget.querySelector("a[rel='next']");
            if (url)
            {
                url = url.href;
                let height = Math.max(body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight)

                // look if user is at bottom of the page
                if (window.scrollY >= height - window.innerHeight - 500) {
                    $(this.paginationTarget).remove()
                    this.loadMore(url);
                }
            }
        }
    }

    loadMore(url){
        let filters = $('#filters');
        let sorters = $('#sorters');
        let hidden_fields = $('#hidden_fields');
        let lastHeader    = $('#lastHeader')
        let params = {};

        if (filters.length > 0) {
            filters.find('select').each(function() {
                let input_dom = $(this);
                params[`${input_dom.attr('id')}`] = input_dom.val();
            })
        }

        if (sorters.length > 0) {
            sorters.find('select').each(function() {
                let input_dom = $(this);
                params[`${input_dom.attr('id')}`] = input_dom.val();
            })
        }

        if (hidden_fields.length > 0) {
            hidden_fields.children('input').each(function() {
                let input_dom = $(this);
                params[`${input_dom.attr('id')}`] = input_dom.val();
            })
        }

        if (lastHeader.length > 0) {
            params['lastHeader'] = lastHeader.data('last-header');
            lastHeader.remove();
        }

        if (url.includes('/search/')) {
            params['search'] = $('#search').val();
            post(url, {
                responseKind: 'turbo-stream',
                body: params
            });
        }
        else {
            get(url, {
                responseKind: 'turbo-stream',
                query: params
            });
        }
    }
}

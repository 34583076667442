import { Controller } from '@hotwired/stimulus'
import {post} from "@rails/request.js";

export default class extends Controller {

    static targets = ['sort']
    static values = {
        url: Array,
        initialOrder: Object
    }

    orderMemory = {}

    sortTargetConnected() {
        this.orderMemory = this.initialOrderValue
    }

    sort(event) {
        let url = $('#ean_search').val() !== '' ? this.urlValue[1] : this.urlValue[0]
        let column = event.target.getAttribute('column')
        let order = this.orderMemory[column]

        post(url, {
                responseKind: 'turbo-stream',
                body: Object.assign(
                    { search: $('#search').val(),
                        ean_search: $('#ean_search').val(),
                        filter: $('#filter').val(),
                        sort: { column: column, order: order }},
                    this.retrieveParams('hidden-field'),
                    this.retrieveParams('filter'))
            }
        )

        this.orderMemory[column] = this.inverseOrder(order)
        this.changeIcons(event.target, order)
    }

    inverseOrder(order) {
        return (order === 'desc') ? 'asc' : 'desc'
    }

    changeIcons(element, order) {
        Array.from($('#record-header').find('.btn-sort')).forEach(ele => {
            if (ele.classList.contains('btn-sort') && ele.childElementCount !== 0) {
                ele.removeChild(ele.getElementsByTagName('div')[0])
            }
        })
        let arrow = document.createElement('div')
        arrow.className = `fa fa-arrow-${(order === 'desc') ? 'down' : 'up'}`
        element.childElementCount === 0 ? element.appendChild(arrow) : element.replaceChild(arrow, element.getElementsByTagName('div')[0])
    }

    retrieveParams(className) {
        let params = document.getElementsByClassName(className)
        let extraParams = {}
        if (params.length === 0) return extraParams

        if (params.length === 1) {
            extraParams[params[0].name] = params[0].value
        }
        else if (className === 'hidden-field' ) {
            Array.from(params).forEach((param) => {
                extraParams[param.name] = param.value
            })
        } else if (className === 'filter') {
            extraParams['filter'] = {}
            Array.from(params).forEach((filterField) => {
                extraParams['filter'][filterField.name.match(/\[(.*)\]/)[1]] = filterField.value
            })
        }
        return extraParams
    }
}

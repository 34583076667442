import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'toggleElement', 'toggleText' ]

    hide_or_show() {
        $(this.toggleElementTargets).toggleClass('d-none d-block')

        if (this.hasToggleTextTarget) {
            this.toggleTextTarget.innerText = this.toggleTextTarget.innerText === 'Voir plus' ? 'Voir moins' : 'Voir plus'
        }
    }
}

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['appellation', 'mention']

    updateAppellation(event) {
        let _this = this;
        let selection = event.target.selectedOptions[0].value
        let params = new URLSearchParams();
        params.append('id', selection.split(',')[0]);
        params.append('type', selection.split(',')[1]);
        $.get(`/cuvees/appellation?${params}`, function (data) {
            _this.appellationTarget.value = data.aop_igp_id
            _this.mentionTarget.value = data.clean_mention_id
        });
    }

    updateSelect(event) {
        let select = $(event.target).closest('.form-group').find('select').get(0)
        let selectStatus = select.disabled
        $(event.target).toggleClass('fa-arrows-repeat fa-check')
        $('#domain-validation-message').toggleClass('d-none d-block')
        select.disabled = selectStatus !== true;
        if (select.disabled === true) {
            this.changeDomain(select.selectedOptions[0].value)
        }
    }

    changeDomain(value) {
        document.getElementById('cuvee_id_domaine').value = value
    }
}

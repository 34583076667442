import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

    static targets = ['storeId', 'startDate', 'endDate']

    async checkUniqueness(event) {
        event.preventDefault()

        let submission = false
        let params = new URLSearchParams();
        params.append('store_id', this.storeIdTarget.value);
        params.append('start_date', this.startDateTarget.value);
        params.append('end_date', this.endDateTarget.value);
        await $.get(`/sales_inventories/check_uniqueness?${params}`, function (data) {
            if (data.exists) {
                submission = confirm('Une analyse de ventes avec les mêmes dates existe déjà, voulez-vous la remplacer ?');
            } else {
                submission = true;
            }
        })
        if (submission) {
            $(event.target).closest('form')[0].requestSubmit()
        }
    }
}

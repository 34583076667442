import {Controller} from '@hotwired/stimulus'
import {get, post} from "@rails/request.js";

export default class extends Controller {

    static targets = ['add', 'remove', 'select']
    static values = {
        choices: Array,
        choicesWithoutSelected: { type: Array, default: [] },
        selected: { type: Array, default: [] },
        partial: String
    }

    initialize() {
        this.setValues()
    }

    addTargetConnected(target) {
        $(target).on('click', () => {
            if (this.choicesWithoutSelectedValue.length < 1) {
                alert('Il n\'y a plus de catégorie disponible')
            } else {
                this.addField()

                this.waitForElement('.nested-fields', this.selectedValue.length + 1).then(() => {
                    this.setValues()
                })
            }
        })
    }

    removeTargetConnected(target) {
        $(target).on('click', async e => {
            this.removeField($(e.currentTarget).closest('turbo-frame')[0].id)

            this.waitForElement('.nested-fields', this.selectedValue.length - 1).then(() => {
                this.setValues()
            });
        })
    }

    choicesWithoutSelectedValueChanged(value , previousValue) {
        if (previousValue === undefined) return;

        let updatedData = this.selectTargets.map((t, idx) => ({
            target: t.id,
            choices: value.concat(Array(this.selectedValue[idx])),
            selected: this.selectedValue[idx]
        }))

        post(`${window.location.origin}/commons/fields_for/update_fields`, {
            responseKind: 'turbo-stream',
            body: { data: updatedData }
        })
    }

    updateField(event) {
        this.setValues()
    }

    addField() {
        post(`${window.location.origin}/commons/fields_for/add_field`, {
            responseKind: 'turbo-stream',
            body: {
                partial: this.partialValue,
                choices: this.choicesWithoutSelectedValue,
                selected: this.choicesWithoutSelectedValue[0]
            }
        })
    }

    removeField(target) {
        let params = new URLSearchParams();
        params.append('target', target);
        get(`${window.location.origin}/commons/fields_for/remove_field?${params}`, {
            responseKind: 'turbo-stream'
        })
    }

    waitForElement(selector, count) {
        return new Promise(resolve => {
            if (document.querySelectorAll(selector).length === count) {
                return resolve(document.querySelectorAll(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelectorAll(selector).length === count) {
                    resolve(document.querySelectorAll(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    setValues() {
        this.selectedValue = this.selectTargets.map(t => [t.selectedOptions[0].innerText, t.selectedOptions[0].value])
        this.choicesWithoutSelectedValue = this.choicesValue.filter(e => !JSON.stringify(this.selectedValue).includes(JSON.stringify(e)))
    }

}

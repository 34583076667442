import {Controller} from '@hotwired/stimulus'
import {get} from "@rails/request.js";

export default class extends Controller {
    static targets = []

    connect() {
        let self = this
        $(document).ready(function() {
            let subQueries = $('.sub-query')
            $.each(subQueries, function (_index, subQuery) {
                self.changeValuesField($(subQuery))
            })
        })
    }

    changeValuesField(subQueryElement) {
        let selectValuesElement = subQueryElement.find('.values.select2')
        let selectAppellationValuesElement = subQueryElement.find('.values.select2-appellations')
        let numberValuesElement = subQueryElement.find('.values.number')
        let method = subQueryElement.find('.method')[0].selectedOptions[0].value
        switch (method) {
            case 'by_price':
                selectValuesElement.attr({ disabled: true }).removeAttr('required')
                selectValuesElement.siblings('.select2-container').attr({ hidden: true })
                selectAppellationValuesElement.attr({ disabled: true }).removeAttr('required')
                selectAppellationValuesElement.parent().attr({ hidden: true })
                numberValuesElement.attr({ required: true }).removeAttr('hidden disabled')
                break;
            case 'by_appellation':
                selectValuesElement.attr({ disabled: true }).removeAttr('required')
                selectValuesElement.siblings('.select2-container').attr({ hidden: true })
                numberValuesElement.attr({ hidden: true, disabled: true }).removeAttr('required')
                selectAppellationValuesElement.attr({ required: true }).removeAttr('disabled')
                selectAppellationValuesElement.parent().removeAttr('hidden')
                break;
            default:
                selectValuesElement.attr({ required: true }).removeAttr('disabled')
                selectValuesElement.siblings('.select2-container').removeAttr('hidden disabled')
                numberValuesElement.attr({ hidden: true, disabled: true }).removeAttr('required')
                selectAppellationValuesElement.attr({ disabled: true }).removeAttr('required')
                selectAppellationValuesElement.parent().attr({ hidden: true })
                break;
        }
    }

    updateConstraint(event) {
        let subQueryElement = $(event.target).closest('.sub-query')
        const elementToObserve = subQueryElement.find('.constraint')[0]
        const observer = new MutationObserver(() => {
            this.changeValuesField(subQueryElement)
            observer.disconnect()
        })
        observer.observe(elementToObserve, { subtree: true, childList: true })
    }

    displayQueries(event) {
        let params = new URLSearchParams();
        params.append('query_ids', Array.from(event.target.selectedOptions).map(i => i.value).toString());
        params.append('target', 'queries');
        get(`/queries/queries_by_id?${params}`, {
            responseKind: 'turbo-stream'
        })
    }

    removeQuery(event) {
        event.target.closest('.sub-query').remove()
    }
}
